import React from 'react';

import './index.css';

function FooterScreen() {
    return (
        <div className='footer-section'>
            <div className='footer-content'>
                Designed & Developed by Collin Jones
            </div>
        </div>
    );
}

export default FooterScreen;
